import { CiStar } from "react-icons/ci";



export const links = [
    {
        name: "Home",
        path: '/#'
    },
    {
        name: "About Us",
        path: '/#testimonials'
    },
    {
        name: "Services",
        path: '/#services'
    },
    {
        name: "FAQ",
        path: '#faq'
    },
    {
        name: "Contact",
        path: '/#contact'
    }
]

// CTA Buttons

export const ctaB = [
    {
        id: 1,
        avatar: require('./images/apple-store.svg'),
        text: "Download on the",
        name: "App Store",
        href: "#"
    },

    {
        id: 2,
        avatar: require('./images/google-play.svg'),
        text: "GET IT ON",
        name: "Google Play",
        href: "#" 
    }
]

// Active users

const user1 = require('./images/img-1.png')
const user2 = require('./images/img-2.png')
const user3 = require('./images/img-3.png')
const user4 = require('./images/img-4.png')
const user5 = require('./images/img-5.png')
const user6 = require('./images/Vector-cross.png')

export const activeUsers = [
    {
        id: 1,
        Image: user1,
        name: "active__user"
    },

    {
        id: 2,
        Image: user2,
        name: "active__user"
    },

    {
        id: 3,
        Image: user3,
        name: "active__user"
    },

    {
        id: 4,
        Image: user4,
        name: "active__user"
    },

    {
        id: 5,
        Image: user5,
        name: "active__user"
    }
]

// FAQS ACTIVE USERS

export const FAQactiveUsers = [
    {
        id: 1,
        Image: user6,
        name: "active__user"
    },

    {
        id: 2,
        Image: user2,
        name: "active__user"
    },

    {
        id: 3,
        Image: user3,
        name: "active__user"
    },

    {
        id: 4,
        Image: user4,
        name: "active__user"
    },

    {
        id: 5,
        Image: user5,
        name: "active__user"
    }
]

export const heroTexts = [
    {
        id: 1,
        text: "Easy to use"
    },

    {
        id: 2,
        text: "Fast Transactions"
    },

    {
        id: 3,
        text: "Exceptional  Support"
    }
]

// quality assurance

const QaIcon1 = require("./images/Vector-guard.png")
const QaIcon2 = require("./images/Vector-star.png")
const QaIcon3 = require("./images/Vector-flash.png")
const QaIcon4 = require("./images/Vector-headset.png")

export const qualityAssure = [
    {
        id: 1,
        icon: QaIcon1,
        text: "Secure and Convenient Transactions"
    },
    {
        id: 2,
        icon: QaIcon2,
        text: "Prompt Settlement"
    },
    {
        id: 3,
        icon: QaIcon3,
        text: "Seamless Experience"
    },
    {
        id: 4,
        icon: QaIcon4,
        text: "Exceptional Customer Support"
    }
]


// Services

const serviceIcon1 = require("./images/Vector-phone.png")
const serviceIcon2 = require("./images/Vector-castle.png")
const serviceIcon3 = require("./images/Vector-electricity.png")
const serviceIcon4 = require("./images/Vector-education.png")
const serviceIcon5 = require("./images/Vector-cable-subscription.png")
const serviceIcon6 = require("./images/Vector-airline.png")

export const servicesData = [
    {
        id: 1,
        Icon1: serviceIcon1,
        serviceTitle: "Airtime & Data Services",
        serviceText: "Subscribe to airtime and data plans with ease using OneDigitPay. Enjoy fast and secure transactions, with discounts available for all networks."
    },
    {
        id: 2,
        Icon1: serviceIcon2,
        serviceTitle: "Payment to Businesses",
        serviceText: "Register to receive online payment from your customers"
    },
    {
        id: 3,
        Icon1: serviceIcon3,
        serviceTitle: "Electricity",
        serviceText: "Recharge your prepaid meter and receive instant token delivery.."
    },
    {
        id: 4,
        Icon1: serviceIcon4,
        serviceTitle: "Education",
        serviceText: "Generate WAEC and NECO result checker PINs and Tokens at unbeatable prices."
    },
    {
        id: 5,
        Icon1: serviceIcon5,
        serviceTitle: "Cable Subscription",
        serviceText: "Instantly active Cable TV subscriptions such as DSTV, GOTV and Startimes with favourable discount."
    },
    {
        id: 6,
        Icon1: serviceIcon6,
        serviceTitle: "Airline",
        serviceText: "Book and pay for local and  international flights with exciting deals"
    }
]


// How does it work

const stepImage1 = require('./images/Vector-download.png')
const stepImage2 = require('./images/Vector-register.png')
const stepImage3 = require('./images/Group-add-funds.png')

export const stepsToFollow = [
    {
        id: 1,
        icon: stepImage1,
        heading: "Download and Install the App",
        text: "Get started in seconds: Search, tap and install our app from the app store to access a universe of possibilities right from your smartphone."
    },

    {
        id: 2,
        icon: stepImage2,
        heading: "Register on OneDigitPay for free",
        text: "Registration is quick and easy, just provide your basic information and create a secure password to access all  the features of our platform. You can register as an individual or business owner"
    },

    {
        id: 3,
        icon: stepImage3,
        heading: "Add Funds and Pay Bill",
        text: "Add funds and pay bills or Send money instantly "
    }
]


// Testimonials Data

const picture1 = require('./images/ruth-kadiri.png')
const picture2 = require('./images/jane-kayode.png')
const picture3 = require('./images/remi-david.png')

export const TestimonialsData = [
    {
        id: 1,
        profile: picture1,
        customer: 'Ruth Kadiri',
        number: '5.0',
        starIcon: <CiStar />,
        testimony: 'Switching to this service for our electricity needs was the best decision we made. Not only are the rates affordable, but the discounts we receive for reselling have helped boost our business profits significantly.'
    },
    
    {
        id: 2,
        profile: picture2,
        customer: 'Jane Kayode',
        number: '5.0',
        starIcon: <CiStar />,
        testimony: 'As a business owner, ease of receiving online payment from my customers on OnedigitPay  merchant service  with instant settlement is a game-changer experience . The rates and competitive. My customers are happy and so I am  and their 24/7 customer support is superb '
    },

    {
        id: 3,
        profile: picture3,
        customer: 'David Remi',
        number: '5.0',
        starIcon: <CiStar />,
        testimony: 'I  use OneDigitPay service to send money to my daughter at school and other  loved and  the experience has been amazing. No complaints at all and the pricing is quite good. Highly recommended for anyone.'
    }
]

// FAQS

export const faqs = [
    {
        id: 1,
        question: "What is the purpose of the app?",
        answer: "Onedigitpay is a one-stop online payment platform designed to ease payment experience for both individuals and businesses. Individual can make payment for over 30 top brand services  including airtime/data purchase, TV subscriptions, electricity other biller services; and also transfer and receive fund from all Nigerian Banks. Our merchant payment service is multi-channel payment gateway designed for business owners to promptly receive payment online from customer  across various channels ie transfers, card , USSD. Businesses can do self-onboarding  in few minutes to receive payment from customers"
    },
    {
        id: 2,
        question: " How do I start  Using the App?",
        answer: "Onedigitpay app is available in Appstore and Playstore  for download and register within few minutes to start using the app. It's also available on the web"
    },
    {
        id: 3,
        question: "How do I setup to receive payment from customers as a business owner?",
        answer: "Setup up your company details and provide the required documentation. You then enable your preferred payment options to start receiving payment. No technical knowledge is required"
    },
    {
        id: 4,
        question: "When do I get settlement for the payment made by customers?",
        answer: "Instant settlement is guaranteed for payment by transfer. Settlement period for others is dependent on the payment channel but not more then 24Hrs"
    },
    {
        id: 5,
        question: "Is there  a cost associated with using the app?",
        answer: "No, the services is free to download and use. However, there may be fees associated with the services"
    }
]


// footer social media data

const socials1 = require('../src/images/Vector-twitter.webp')
const socials2 = require('../src/images/Vector-facebook.webp')
const socials3 = require('../src/images/Vector-insta.webp')
const socials4 = require('../src/images/Vector-whatsapp.webp')
const socials5 = require('../src/images/Vector-linkedin.webp')

export const socialLinks = [
    {
        id: 1,
        socialIcon: socials1,
        path: '',
    },
    {
        id: 2,
        socialIcon: socials2,
        path: 'https://web.facebook.com/profile.php?id=61558248428563',
    },
    {
        id: 3,
        socialIcon: socials3,
        path: 'https://www.instagram.com/onedigitpay/',
    },
    {
        id: 4,
        socialIcon: socials4,
        path: 'https://wa.me/+2349034241600',
    },
    {
        id: 5,
        socialIcon: socials5,
        path: 'https://www.linkedin.com/company/onedigitpay/',
    }
]