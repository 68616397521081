import laptopImage from '../images/mack-2vg.svg';


const MerchantService = () => {
  return (
    <section className="merchant__service-container" id="merchant"> 
        <div className="content__text-container container-margin" data-aos="fade-up">

            {/* only to be displayed in smaller screens */}
            <div className="mack__image-2">
              <img src={laptopImage} alt="" />
            </div>

            <div className="text_content">
                <h5>Convenient Merchant Payment</h5>

                <p>
                We offer businesses a fast and secure way to get paid and scale their business with analytics tools to monitor their growth and unparalleled customer experience <br /><br />

                Our payment gateway service enables merchants to receive payments from customers while the merchant is promptly settled
                </p>
            </div>

            {/* I want to use a background Image */}
            <div className="macBook_image"></div>
        </div>
    </section>
  )
}

export default MerchantService