import { Link } from "react-router-dom";
import Card from "./Card";
import { stepsToFollow } from "../data";
import appStore from '../images/apple-store.svg';
import googlePlay from '../images/google-play.svg';
import phoneInHand from './../images/group-phone-hand2.webp'

const Steps = () => {
  return (
    <section className="steps__section">
        <div className="container steps__section-container">
            <div className="steps__section-left">
                {/* 1st item left*/}
                <div className="title__block" data-aos="slide-down">
                    <p className="small__header-text">
                    How to get started with OneDigitPay
                    </p>

                    <h3>How Does It Work?</h3>
                </div>

                {/* 2nd item left */}
                <div className="instruction__block" data-aos="fade-right">
                    <div className="instruction__tray">
                        {
                            stepsToFollow.map((step) => {
                                return (
                                    <Card key={step.id}>
                                        <div className="outer__circle">
                                        <div className="image__circle">
                                            <img src={step.icon} alt="icon" />
                                        </div>

                                        </div>
                                        <div className="text__body">
                                            <h3>{step.heading}</h3>

                                            <p>{step.text}</p>
                                        </div>
                                    </Card>
                                )
                            })
                        }
                    </div>

                    {/* CTA BUTTON  */}
                    <div className="cta-s">

                    {/* <!-- APPLE STORE --> */}
                    <Link to="#" className="btn-s btn-primary-s" target="_blank">
                        <div className="cta-logo-s">
                            <img src={appStore} alt="apple-logo" />
                        </div>

                        <span>
                            <small>Download on the</small>
                            <h3>App store</h3>
                        </span>
                    </Link>

                    {/* <!-- GOOGLE PLAY STORE --> */}
                    <Link to="#" className="btn-s" target="_blank">
                        <div class="cta-logo-s">
                            <img src={googlePlay} alt="google-play" /> 
                        </div>

                        <span>
                            <small>GET IT ON</small>
                            <h3>Google Play</h3>
                        </span>
                    </Link>
                    </div>
                </div>
            </div>
            <div className="steps__section-right">
                <Card>
                    <img src={phoneInHand} alt="" />
                </Card>
                
            </div>
        </div>
    </section>
  )
}

export default Steps