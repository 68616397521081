import Card from "./Card";
import { qualityAssure } from "../data";

const QualityAssurance = () => {
  return (
    <section className="qa-section">
        {/* margin top is 40.4rem */}
        <div className="qa-container" data-aos="fade-up">
            <div className="qa__card">
                {
                    qualityAssure.map((assurance) => {
                        return (
                            <Card key={assurance.id}>
                                <img src={assurance.icon} alt="Logo" />

                                <p>{assurance.text}</p>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default QualityAssurance