import Card from "./Card"
import { servicesData } from "../data";

const Services = () => {
  return (
    <section className="services-section container-mini" id="services">
        {/* margin top is 40.4rem */}
        <div className="services-container" data-aos="fade-up">

            <div className="section__head">
                <h1>Discover Top-tier <span>Services</span></h1>

                <p>We offer online financial services targeted at individuals and Businesses</p>
            </div>
            <div className="service__card">
                {
                    servicesData.map((service) => {
                        return (
                            <Card key={service.id}>

                                <div className="empty"></div>
                            <div className="image-icon">
                            <img src={service.Icon1} alt="" />
                            </div>

                                <h5>{service.serviceTitle}</h5>

                                <p>{service.serviceText}</p>
                            </Card>
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default Services