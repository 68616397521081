import React from 'react';
import { FAQactiveUsers } from '../data';
import Card from './Card';
import PlusSign from './PlusSign';
import { faqs } from "../data";
import FAQ from "./FAQ";

const FAQs = () => {
  return (
    <section className="faq__section" id="faq">
        <div className="faq__section-container container" data-aos="slide-up">
            <div className="faq__left-section">
                {/* Active users div */}
                <div className="active__users">
                    <div className="images__tray">
                        {
                            FAQactiveUsers.map((user) => {
                                return (
                                    <Card key={user.id}>
                                        <img src={user.Image} alt={user.name}/>
                                    </Card>
                                )
                            })
                        }
                    </div>

                    <div className="active_text">
                        <div className="numbers">
                        <h5>34k</h5>
                        <PlusSign />
                        </div>
                        
                        <p className="txt">Active users</p>
                    </div>
                </div>

                <h4>
                    Frequently Asked <br /> Questions
                </h4>

                <p className="small-faq-txt">
                    Here are some Frequently Asked Questions (FAQs) along with answers for OneDigitPay App
                </p>
            </div>
            <div className="faq__right-section">
                    <div className="faqs__wrapper">
                {
                    faqs.map(({id, question, answer}) => {
                        return <FAQ key={id} question={question} answer={answer} />
                    })
                }
                </div>
            </div>
        </div>
    </section>
  )
}

export default FAQs