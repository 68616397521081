
import React from 'react';
// import { Link, NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from '../images/desktop-logo.webp';
import LogoMobile from '../images/desktop-logo.webp';
import { FaBars } from 'react-icons/fa6';
import {FaXmark} from 'react-icons/fa6';

import './navbar.css';
import { links } from '../data';
import { useState } from 'react';

const Navbar = () => {

    const [isNavShowing, setIsNavShowing] = useState();

  return (
    <nav>
        <div className="container nav__container">

            {/* desktop logo */}
            <Link to="#" className="logo">
                <img src={Logo} alt="nav logo" />
            </Link>

            {/* mobile logo */}
            <Link to="#" className="logo-m" onClick={() => setIsNavShowing(false)}>
                <img src={LogoMobile} alt="nav logo" />
            </Link>

            {/* The navigation section */}
            <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
                {
                    links.map(({name, path}, index) => {
                        return (
                            <li key={index}>
                                <Link smooth to={path} onClick={() => setIsNavShowing(prev => !prev)}
                                >
                                    {name}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>

            
            <div className="log-in-signUp">
                <Link to='#' className='btn first'>Login</Link>
                <Link to='#' className='btn second'>Sign Up</Link>
            </div>

            <button className="nav__toggle-btn" onClick={() => setIsNavShowing(prev => !prev)}>
                {
                    isNavShowing ? <FaXmark /> : <FaBars />
                }
            </button>
        </div>
    </nav>
  )
}

export default Navbar