import PayServe from "../images/paymentServices.webp";
import PlusSignPay from "./PlusSignPay";
const PaymentService = () => {
  return (
    <section className="payment__service-section">
        <div className="payment__service-container container-payment">
            <div className="header__heading" data-aos="zoom-in" data-aos-duration="1000">
                <h2>
                Over <span className="red__text">30<PlusSignPay className="plus-sign" /></span> top brand service providers are available on OnedigitPay for your payment convenience      
                </h2>
            </div>

            <div className="payment__service-image">
                <img src={PayServe} alt="payment-Logo" />
            </div>
        </div>
    </section>
  )
}

export default PaymentService