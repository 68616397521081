import React from 'react';

const PlusSignPay = ({ style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="red"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        color: 'blue',
        stroke: '#db1d1d',
        strokeWidth: '3px',
        marginBottom: '-6px',
        ...style, // Allow additional styles to be passed in
      }}
    >
      <line x1="12" y1="5" x2="12" y2="19" />
      <line x1="5" y1="12" x2="19" y2="12" />
    </svg>
  );
};

export default PlusSignPay;
