// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import FooterLogo from '../images/botom-logo-replace.webp';
import { socialLinks } from "../data";

const Footer = () => {
  return (
    <footer className="footer__section">
        <div className="footer__section-container container">
            <div className="footer__1">
                <img src={FooterLogo} alt="" />

                <p>We offer online financial services targeted at individuals and Businesses</p>
            </div>
            <div className="footer__2">
                <h4>Company</h4>

                <ul className="company">
                    <li>
                        <Link to='/#'>Home</Link>
                    </li>
                    <li>
                        <Link to='/#merchant'>About Us</Link>
                    </li>
                    <li>
                        <Link to='/#contact'>Contact Us</Link>
                    </li>
                    <li>
                        <Link to='#'>Terms of Use</Link>
                    </li>
                </ul>
            </div>
            <div className="footer__3">
            <h4>Services</h4>

            <ul className="services">
                <li>Airtime & Data</li>
                <li>Cable Subscription</li>
                <li>Education</li>
                <li>Bill Payment</li>
                <li>Utility Payments</li>
                <li>Send Money</li>
                <li>Airline Payment</li>
            </ul>
            </div>
            <div className="footer__4">
                <h4>Follow Us</h4>

                <ul className="social-media">
                                
                    {
                    socialLinks.map((social) => {
                        return (
                            <li key={social.id}>
                        <Link to={social.path} target="__blank">
                            <img src={social.socialIcon} alt="" />
                        </Link>
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        </div>
    </footer>
  )
}

export default Footer