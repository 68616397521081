import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';

import Home from './pages/home/home';
import ReactGA from 'react-ga';


const TRACKING_ID = "G-LWTTE5EFWN";
ReactGA.initialize(TRACKING_ID);
const App = () => {
  return (
    <BrowserRouter>
        <Navbar />

        <Routes>
            <Route index element={<Home/>} />
        </Routes>
    </BrowserRouter>
  )
}

export default App