import { Link } from "react-router-dom";
import ImageQ from '../images/quadrant.svg';
import ImageLap from '../images/phone-in-hand-first.png'
import ImageGroup2 from '../images/group-2.png'
import appStore from '../images/apple-store.svg';
import googlePlay from '../images/google-play.svg';
import Card from "./Card";
import { activeUsers } from "../data";
import PlusSign from "./PlusSign";

const MainHeader = () => {
  return (
    <header className="main__header">
        <div className="container main__header-container">
            <div className="main__header-left" data-aos="fade-right" data-aos-offset="120">
                <h1>
                Secure and Reliable <br /> Online <span className="custom-underline" data-aos="zoom-in-right" data-aos-duration="2500" data-aos-offset="50">Payment Services</span> <br /> Trusted by  Individuals <br /> and Businesses
                </h1>

                <h4>Enjoy a seamless and  haste-free payment experience.</h4>

                <div className="cta">

                {/* <!-- APPLE STORE --> */}
                <Link to="#" className="btn btn-primary" target="_blank">
                    <div className="cta-logo">
                        <img src={appStore} alt="apple-logo" />
                    </div>

                    <span>
                        <small>Download on the</small>
                        <h3>App store</h3>
                    </span>
                </Link>

                {/* <!-- GOOGLE PLAY STORE --> */}
                <Link to="#" className="btn" target="_blank">
                    <div className="cta-logo">
                        <img src={googlePlay} alt="google-play" /> 
                    </div>

                    <span>
                        <small>GET IT ON</small>
                        <h3>Google Play</h3>
                    </span>
                </Link>
                </div>


                {/* To be displayed only on mobile screen */}

                <div className="log-in-signUp-h">
                    <Link to='#' className='btn-h first-h'>Login</Link>
                    <Link to='#' className='btn-h second-h'>Sign Up</Link>
                </div>

                {/* Active users div */}
                <div className="active__users">
                    <div className="images__tray">
                        {
                            activeUsers.map((user) => {
                                return (
                                    <Card key={user.id}>
                                        <img src={user.Image} alt={user.name}/>
                                    </Card>
                                )
                            })
                        }
                    </div>

                    <div className="active_text">
                        <div className="numbers">
                        <h5>34k</h5>
                        <PlusSign />
                        </div>
                        
                        <p className="txt">Active users</p>
                    </div>
                </div>
            </div>


            <div className="main__header-right">
                {/* texts right hand */}
                <div className="hero__texts">
                    {/* {
                        heroTexts.map((text) => {
                            return (
                                <Card key={text.id}>
                                    <p className="hero__text">{text.text}</p>
                                </Card>
                            )
                        })
                    } */}

                    <p className="hero__text" data-aos="fade-left" data-aos-easing="ease-in-sine">Easy to use</p>
                    <p className="hero__text">Fast Transactions</p>
                    <p className="hero__text" data-aos="flip-right" >Exceptional  Support</p>
                </div>

                

                <div className="circle-container">
                <div className="transparent__circle">
                    <div className="inner__circle">
                        {/* The card contains the phone in hand photo */}
                        <Card>
                            <img src={ImageLap} alt="" />
                        </Card>
                    </div>
                        <Card className="lap">
                        <img src={ImageLap} alt="" />
                        </Card>
                </div>
                </div>

                    <Card className="quadrant__image">
                    <img src={ImageQ} alt="" />
                    </Card>

                    <Card className="grouped__photo">
                        <img src={ImageGroup2} alt="" />
                    </Card>
            </div>
        </div>
    </header>
  )
}

export default MainHeader