
import MainHeader from '../../components/MainHeader';
import QualityAssurance from '../../components/qualityAssurance';
import Services from '../../components/Services';
import MerchantService from '../../components/merchantService';
import Steps from '../../components/steps';
import Testimonials from '../../components/testimonials';
import PaymentService from '../../components/paymentService';
import ContactForm from '../../components/contactForm';
import Footer from '../../components/footer';
// CSS
import FAQs from '../../components/FAQs';
import './home.css';
import '../../components/qualityAssurance.css';
import '../../components/services.css';
import '../../components/merchantService.css';
import '../../components/steps.css';
import '../../components/testimonials.css'
import '../../components/paymentService.css';
import '../../components/faq.css';
import '../../components/contactForm.css';
import '../../components/footer.css';

const Home = () => {
  return (
    <>
      <MainHeader />
      <QualityAssurance />
      <Services />
      <MerchantService />
      <Steps />
      <Testimonials />
      <PaymentService />
      <FAQs />
      <ContactForm />
      <Footer />
    </>
  )
}

export default Home