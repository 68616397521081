import { TestimonialsData } from "../data";
import Card from "./Card";
import { TiStarFullOutline } from "react-icons/ti";


const Testimonials = () => {
return (
    <section className="testimonial-section" id="testimonials">
        <div className="testimonial__container container" data-aos="fade-up">
            <div className="testimonial__header">
                <p className="head-txt">Testimonials</p>

                <h3>What our customers say about us</h3>
            </div>

            
            <div className="testimonial__card-pack container">
                {
                    TestimonialsData.map((testimony) => {
                        return (
                            <Card key={testimony.id}>
                                <div className="customer_profile">
                                    <div className="customer__photo">
                                        <img src={testimony.profile} alt="Logo" />
                                    </div>
                                    <div className="customer__name-rate">
                                        <h4>{testimony.customer}</h4>
                                        
                                        <div className="star-rate">
                                        <p>{testimony.number}</p>
                                        <p className="stars">
                                        {Array.from({ length: parseInt(testimony.number) }).map((_, index) => (<TiStarFullOutline key={index} style={{ fill: "#FABD00" }} />))
                                        }
                                        </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="customer__words">
                                    <p>{testimony.testimony}</p>
                                </div>
                            </Card>
                        )
                    })
                }
            </div>
        </div>

    </section>
)
}

export default Testimonials