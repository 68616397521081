import React from 'react';
import { useState } from 'react';
import KiteImage from '../images/Vector-submit.png';
import Card from './Card';
import Swal from 'sweetalert2';

const ContactForm = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: ''
    });
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const { firstName, lastName, phone, email, message } = formData;
    
        // Check if any field is empty
        if (!firstName || !lastName || !phone || !email || !message) {
        alert('Please fill in all fields');
        return;
        }
    
        // Construct the request body with form data
        const requestBody = {
        firstName,
        lastName,
        phone,
        email,
        message
        };
    
        // Your backend URL where the form data will be sent
        const backendUrl = 'https://db-notion-writer.dee-femifose.workers.dev/api/submit';
    
        try {
        const response = await fetch(backendUrl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });
    
        if (response.ok) {
            // Handles success,
            console.log('Form submitted successfully!');
            // The toast
            Swal.fire({
                icon: 'success',
                title: 'Form submitted successfully',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                }
            });

            // Clear the form after successful submission
            setFormData({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            message: ''
            });
        } else {
            // Handle error
            console.error('Form submission failed');
        }
        } catch (error) {
        console.error('Error submitting form:', error);
        }
    };

  return (
    <section className="contact__section" id="contact">
        <div className="contact__section-container container-contact">
            <div className="form__header">
                <Card>
                    <h2 className='form__header-head'>Contact OneDigitPay</h2>

                    <p>Reach out to us via the contact form, and we'll <br />ensure a swift and personalized response.</p>
                </Card>
            </div>

            {/* The contact form section */}
            <div className="form__container">
                <form onSubmit={handleSubmit} className="form">
                    {/* 1 */}
                    <div className="form__row1">
                    <label>
                        {/* First Name: */}
                        <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="First name"
                        required
                        />
                    </label>
                    <label>
                        {/* Last Name: */}
                        <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Last name"
                        required
                        />
                    </label>
                    </div>

                    {/* 2 */}
                    <div className="form__row2">
                    <label>
                        {/* Phone No.: */}
                        <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone No."
                        required
                        />
                    </label>
                    <label>
                        {/* Email: */}
                        <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                        />
                    </label>
                    </div>

                    {/* 3 */}
                    <div className="form__row3">
                    <label>
                        {/* Message: */}
                        <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="Purpose"
                        />
                    </label>
                    </div>
                    
                    {/* 4 */}
                    <div className="form__row4">
                        
                        <button type="submit">Submit <img src={KiteImage} alt=''/></button>
                    </div>
                </form>
            </div>
        </div>
    </section>
  )
}

export default ContactForm